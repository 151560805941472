import React from "react"
import styled from "styled-components"

import { minD, maxD } from "../../assets/styles/helpers/devices"
import { Box, UnstyledBoxes } from "../case-study/common/boxes"

import { BoxIcon } from "../case-study/common/boxes"
import {
  GrayContainer,
  LineWrapperWithBottomSpace,
} from "../global/common/containers"
import { UnstyledList } from "../global/common/essentials"
import { greenBox } from "../global/common/ornaments"
import { CenterTitle, BigDescription } from "../global/common/text"

import SiteLanguage from "../../helpers/siteLanguage"

const ServicesContainer = styled(UnstyledBoxes)`
  position: relative;

  @media ${minD.mobileL} {
    ${greenBox}
  }

  &::after {
    right: 20px;
    bottom: -45px;
  }
`

const Service = styled(Box)`
  display: flex;
  align-items: center;

  width: 33.33%;
  padding: 3rem;

  @media ${maxD.laptop} {
    width: 50%;
    padding: 2.4rem;
  }

  @media ${maxD.tablet} {
    width: 100%;
  }

  @media ${maxD.tablet} {
    padding: 30px;
  }
`

const ServiceHeader = styled.h3`
  font-size: 1.45rem;
  padding-left: 1.8rem;
`

const LogosWrapper = styled.article`
  @media ${minD.tabletL} {
    display: flex;
  }
`

const LogosTitle = styled(BigDescription)`
  padding: 4rem 0 2.2rem;

  @media ${maxD.mobileL} {
    padding: 3rem 0 1.4rem;
  }
`

const LogosType = styled.figure`
  @media ${minD.tabletL} {
    width: 50%;
    padding-right: var(--section-spacing);
  }

  @media ${maxD.mobileL} {
    &:last-of-type {
      & > ${LogosTitle} {
        padding: 1.5rem 0 1.4rem;
      }
    }
  }
`

const Logos = styled(UnstyledList)`
  margin: 0 -0.8rem;
`

const LogoContainer = styled.li`
  display: inline-block;
  width: 16.66%;
  padding: 0.8rem;

  @media ${maxD.mobileM} {
    width: 25%;
  }
`

const Logo = styled.img`
  width: 100%;
  height: 30px;
  object-fit: contain;
`

const Services = ({ data }) => {
  const {
    offerServicesTitle: title,
    offerServicesFieldList: list,
    offerServicesTechnologies: technologiesData,
    offerServicesFiles: filesData,
  } = data

  const technologies = {
    title: technologiesData.header,
    logos: technologiesData.logos,
  }

  const files = {
    title: filesData.header,
    logos: filesData.logos,
  }

  return (
    <GrayContainer>
      <LineWrapperWithBottomSpace>
        <CenterTitle
          dangerouslySetInnerHTML={{ __html: title }}
          style={{ "--padding-bottom": "5rem" }}
        />
        <ServicesContainer>
          {list &&
            list.map(({ icon, name }, i) => (
              <Service key={`service-${i}`}>
                <BoxIcon dangerouslySetInnerHTML={{ __html: icon }} />
                <ServiceHeader as="h3">{name}</ServiceHeader>
              </Service>
            ))}
        </ServicesContainer>
        <LogosWrapper flex>
          <LogosType>
            <LogosTitle>{technologies.title}</LogosTitle>
            <Logos>
              {technologies.logos &&
                technologies.logos.map(({ alt, localFile, id, atttachement_pl }) => {
                  const src = localFile.publicURL
                  console.log(atttachement_pl)
                  console.log(alt)
                  alt = (SiteLanguage() !== "en" && atttachement_pl.altTextPl) ? atttachement_pl.altTextPl : alt
                  console.log(alt)
                  return (
                    <LogoContainer key={id}>
                      <Logo {...{ src, alt }} />
                    </LogoContainer>
                  )
                })}
            </Logos>
          </LogosType>
          <LogosType>
            <LogosTitle>{files.title}</LogosTitle>
            <Logos>
              {files.logos &&
                files.logos.map(({ alt, localFile, id, atttachement_pl }) => {
                  const src = localFile.publicURL
                  alt = (SiteLanguage() !== "en" && atttachement_pl.altTextPl) ? atttachement_pl.altTextPl : alt

                  return (
                    <LogoContainer key={id}>
                      <Logo {...{ src, alt }} />
                    </LogoContainer>
                  )
                })}
            </Logos>
          </LogosType>
        </LogosWrapper>
      </LineWrapperWithBottomSpace>
    </GrayContainer>
  )
}

export default Services
